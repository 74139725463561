import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { PhoneIcon, EnvelopeIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { parsePhoneNumber, AsYouType, getCountryCallingCode } from 'libphonenumber-js';

// Configuration
const backendUrl = process.env.REACT_APP_BACKEND_URL;
console.log(`backendUrl: ${backendUrl}`);

function App() {
  const [step, setStep] = useState(1); // Track the current step
  const [businessEmail, setBusinessEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [callStatus, setCallStatus] = useState('');
  const [loading, setLoading] = useState(false); // Track loading state
  const [errors, setErrors] = useState({ email: '', phone: '' });
  const [callSummary, setCallSummary] = useState({ summary: '', crmActions: [] });
  const [showSummary, setShowSummary] = useState(false);
  const [callInProgress, setCallInProgress] = useState(false);
  const [callSid, setCallSid] = useState(null);
  const [countryCode, setCountryCode] = useState('');
  const [formattedNumber, setFormattedNumber] = useState('');

  // List of popular non-business email domain to reject
  const nonBusinessDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'aol.com'];

  // Validate Business Email
  const validateBusinessEmail = (email) => {
    const domain = email.split('@')[1].toLowerCase();
    if (nonBusinessDomains.includes(domain)) {
      return false;
    }
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle Business Email Submission
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setErrors({ ...errors, email: '' });

    if (!validateBusinessEmail(businessEmail)) {
      setErrors({ ...errors, email: 'please enter a valid business email address.' });
      return;
    }

    setLoading(true);
    try {
      console.log('Submitting business email:', businessEmail);
      // Extract companyDomain from businessEmail
      const companyDomain = businessEmail.split('@')[1].toLowerCase();

      const response = await axios.post(
        `${backendUrl}/api/register-business`,
        {
          companyDomain,
        },
        {
          headers: {
            'X-Business-Email': businessEmail, // Pass the business email in headers
          },
        }
      );

      console.log('Email registration successful:', response.data);
      setCallStatus('business information processed successfully.');
      setStep(2); // Proceed to the next step
    } catch (error) {
      console.error('Error registering email:', error);
      setErrors({
        ...errors,
        email: error.response?.data?.error || 'Failed to process business email.',
      });
      alert(error.response?.data?.error || 'an error occurred while processing your business email.');
    } finally {
      setLoading(false);
    }
  };

  // Add this helper function near the top of the component
  const extractPhoneNumber = (fullNumber) => {
    // Remove all non-digit characters except +
    const cleaned = fullNumber.replace(/[^\d+]/g, '');
    
    // If the number starts with +, attempt to extract country code
    if (cleaned.startsWith('+')) {
      // Most country codes are 1-3 digits after the +
      const matches = cleaned.match(/^\+(\d{1,3})(.*)/);
      if (matches) {
        const [_, detectedCountryCode, remainingNumber] = matches;
        return {
          countryCode: `+${detectedCountryCode}`,
          number: remainingNumber
        };
      }
    }
    
    // If no country code detected, return the cleaned number
    return {
      countryCode: countryCode, // Use existing country code
      number: cleaned.replace(/^\+/, '') // Remove + if present
    };
  };

  // Handle Phone Number Submission
  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    setErrors({ ...errors, phone: '' });
    setLoading(true);
    setCallInProgress(true);
    
    try {
      const response = await axios.post(`${backendUrl}/api/create-session`, {
        phoneNumber
      }, {
        headers: {
          'X-Business-Email': businessEmail,
        },
      });
      console.log('Call initiated:', response.data);
      setCallSid(response.data.callSid); // Store callSid
    } catch (error) {
      console.error('Error initiating call:', error);
      setErrors({
        ...errors,
        phone: error.response?.data?.error || 'Failed to initiate call.'
      });
      setCallInProgress(false);
    } finally {
      setLoading(false);
    }
  };

  // Add WebSocket reference
  const wsRef = useRef(null);

  // Update WebSocket connection setup
  useEffect(() => {
    if (step === 2 && callInProgress) {
      console.log('Setting up WebSocket connection...');
      const wsUrl = `${backendUrl.replace('http', 'ws')}/media-stream`;
      console.log('WebSocket URL:', wsUrl);
      
      wsRef.current = new WebSocket(wsUrl);
      
      wsRef.current.onopen = () => {
        console.log('WebSocket connection established');
      };

      wsRef.current.onmessage = (event) => {
        console.log('Raw WebSocket message received:', event.data);
        
        try {
          const data = JSON.parse(event.data);
          console.log('Parsed WebSocket data:', data);
          
          if (data.event === 'call_summary') {
            console.log('Call summary received:', data.summary);
            
            // Parse summary and actions
            const [summaryText, actionsText] = data.summary.split('CRM_ACTIONS:');
            console.log('Split summary:', { summaryText, actionsText });
            
            const formattedSummary = {
              summary: summaryText.replace('SUMMARY:', '').trim(),
              crmActions: actionsText ? actionsText.trim().split('\n')
                .filter(line => line.trim().startsWith('-'))
                .map(line => line.trim()) : []
            };
            
            console.log('Formatted summary:', formattedSummary);
            
            setCallSummary(formattedSummary);
            setShowSummary(true);
            setStep(3);
            setCallInProgress(false);
          }
        } catch (error) {
          console.error('Error processing WebSocket message:', error);
        }
      };

      wsRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      wsRef.current.onclose = () => {
        console.log('WebSocket connection closed');
      };

      return () => {
        if (wsRef.current) {
          console.log('Cleaning up WebSocket connection');
          wsRef.current.close();
        }
      };
    }
  }, [step, callInProgress]);

  // Add polling effect
  useEffect(() => {
    let pollInterval;
    
    if (callSid && callInProgress) {
      console.log('Starting to poll for call summary...');
      pollInterval = setInterval(async () => {
        try {
          const response = await axios.get(`${backendUrl}/api/call-summary/${callSid}`);
          if (response.data.summary) {
            console.log('Summary received:', response.data.summary);
            const [summaryText, actionsText] = response.data.summary.split('CRM_ACTIONS:');
            
            setCallSummary({
              summary: summaryText.replace('SUMMARY:', '').trim(),
              crmActions: actionsText ? actionsText.trim().split('\n')
                .filter(line => line.trim().startsWith('-'))
                .map(line => line.trim()) : []
            });
            setShowSummary(true);
            setStep(3);
            setCallInProgress(false);
            clearInterval(pollInterval);
          }
        } catch (error) {
          console.error('Error polling for summary:', error);
        }
      }, 5000); // Poll every 5 seconds
    }
    
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [callSid, callInProgress]);

  // Update the SummaryDisplay component
  const SummaryDisplay = () => {
    console.log('Rendering SummaryDisplay with:', callSummary);
    
    return (
      <div className="space-y-6 animate-fadeIn">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">call summary</h2>
          <p className="text-gray-700 leading-relaxed">{callSummary.summary}</p>
        </div>
        
        {callSummary.crmActions?.length > 0 && (
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">
              suggested actions
            </h2>
            <ul className="space-y-2">
              {callSummary.crmActions.map((action, index) => (
                <li key={index} className="flex items-start gap-2">
                  <span className="text-blue-500">•</span>
                  <span>{action}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        
        {/* Contact section */}
        <div className="bg-gradient-to-br from-primary-50 to-primary-100 p-6 rounded-lg shadow-lg border border-primary-200">
          <h2 className="text-xl font-semibold mb-3 text-primary-800">
            ready to get started?
          </h2>
          <p className="text-primary-700 mb-4">
            see how jerrryy can be your reliable backup when you're unavailable. perfect for peaceful nights and busy days.
          </p>
          <a
            href="mailto:hey@jerrryy.com"
            className="inline-flex items-center gap-2 bg-primary-600 text-white px-6 py-3 rounded-lg hover:bg-primary-700 transition-colors"
          >
            <EnvelopeIcon className="w-5 h-5" />
            contact us
          </a>
        </div>
      </div>
    );
  };

  // Add loading state for call
  const CallStatus = () => (
    <div className="text-center py-4">
      <div className="animate-pulse flex flex-col items-center">
        <div className="w-3 h-3 bg-green-500 rounded-full mb-2"></div>
        <p className="text-gray-600">call in progress...</p>
        <p className="text-sm text-gray-500">summary will appear when call ends</p>
      </div>
    </div>
  );

  // Near the end of the component, before the return
  console.log('Current state:', {
    step,
    callInProgress,
    showSummary,
    callSummary
  });

  // Add effect to detect user's country on component mount
  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setCountryCode(data.country_calling_code || '+1'); // Default to +1 if detection fails
      } catch (error) {
        console.error('Error detecting country:', error);
        setCountryCode('+1'); // Default to +1 if detection fails
      }
    };
    
    detectCountry();
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center p-4 bg-gradient-to-br from-blue-50 to-indigo-50">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="card p-8 w-full max-w-md"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <h1 className="text-3xl font-bold mb-2 text-center bg-gradient-to-r from-primary-600 to-primary-500 text-transparent bg-clip-text">
            your 24/7 backup
          </h1>
          <p className="text-secondary-500 mb-8 text-center">
            never miss a business opportunity
          </p>
        </motion.div>

        {step === 1 && (
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-6"
          >
            <div className="bg-gradient-to-br from-blue-50 to-indigo-50 p-6 rounded-xl border border-blue-100">
              <h3 className="font-semibold text-primary-700 flex items-center gap-2">
                <CheckCircleIcon className="w-5 h-5" />
                jerrryy works while you rest
              </h3>
              <ul className="mt-4 space-y-3">
                <li className="flex items-start gap-2">
                  <span className="text-primary-600">•</span>
                  <span>takes calls 24/7, even when you're unavailable</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-primary-600">•</span>
                  <span>answers questions about your services accurately</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-primary-600">•</span>
                  <span>provides detailed call summaries and actions</span>
                </li>
              </ul>
            </div>
            
            <form onSubmit={handleEmailSubmit} className="space-y-4">
              <div>
                <label className="block text-secondary-700 mb-2">
                  <EnvelopeIcon className="w-5 h-5 inline mr-2" />
                  business email
                </label>
                <input
                  type="email"
                  className="input-field"
                  value={businessEmail}
                  onChange={(e) => setBusinessEmail(e.target.value)}
                  placeholder="yourname@company.com"
                />
                {errors.email && (
                  <motion.p 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-red-500 text-sm mt-1"
                  >
                    {errors.email}
                  </motion.p>
                )}
              </div>
              <button type="submit" className="btn-primary">
                {loading ? 'processing...' : 'next →'}
              </button>
            </form>
          </motion.div>
        )}

        {step === 2 && !callInProgress && (
          <div>
            <p className="mb-4 text-gray-600">
              enter your phone number to experience how jerrryy handles calls.
            </p>
            <form onSubmit={handlePhoneSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">enter your phone number:</label>
                <div className="relative mt-1 flex gap-2">
                  {/* Country code input */}
                  <input
                    type="text"
                    value={countryCode}
                    onChange={(e) => {
                      let input = e.target.value;
                      if (!input.startsWith('+')) {
                        input = '+' + input;
                      }
                      setCountryCode(input);
                      
                      // Update full number when country code changes
                      if (formattedNumber) {
                        try {
                          const phoneNumber = parsePhoneNumber(formattedNumber, 'US');
                          setPhoneNumber(input + phoneNumber.nationalNumber);
                        } catch (error) {
                          console.error('Error parsing phone number:', error);
                        }
                      }
                    }}
                    className="min-w-[3.5rem] w-[4.5rem] px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="+1"
                  />
                  
                  {/* Phone number input */}
                  <input
                    type="tel"
                    value={formattedNumber}
                    onChange={(e) => {
                      let input = e.target.value;
                      
                      try {
                        // Handle full number paste
                        if (input.includes('+')) {
                          const phoneNumber = parsePhoneNumber(input);
                          if (phoneNumber) {
                            setCountryCode(`+${phoneNumber.countryCallingCode}`);
                            setFormattedNumber(phoneNumber.nationalNumber);
                            setPhoneNumber(phoneNumber.number);
                            return;
                          }
                        }
                        
                        // Handle regular input
                        const asYouType = new AsYouType();
                        asYouType.input(countryCode + input);
                        const phoneNumber = asYouType.getNumber();
                        
                        if (phoneNumber) {
                          setFormattedNumber(phoneNumber.nationalNumber);
                          setPhoneNumber(phoneNumber.number);
                        } else {
                          setFormattedNumber(input.replace(/\D/g, ''));
                          setPhoneNumber(countryCode + input.replace(/\D/g, ''));
                        }
                      } catch (error) {
                        console.error('Error formatting phone number:', error);
                        // Fallback to basic formatting
                        const cleaned = input.replace(/\D/g, '');
                        setFormattedNumber(cleaned);
                        setPhoneNumber(countryCode + cleaned);
                      }
                    }}
                    required
                    placeholder="9876543210"
                    className="flex-1 px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                    inputMode="tel"
                  />
                </div>
                {errors.phone && (
                  <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
                )}
              </div>
              <button
                type="submit"
                className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 disabled:bg-green-300"
                disabled={loading}
              >
                {loading ? 'initiating call...' : 'start call'}
              </button>
            </form>
          </div>
        )}

        {step === 2 && callInProgress && <CallStatus />}
        {step === 3 && (
          <>
            {console.log('Rendering step 3')}
            <SummaryDisplay />
          </>
        )}
      </motion.div>
    </div>
  );
}

export default App;
